import React from 'react';
import styled from 'styled-components/macro';

const TopContainer = styled.div`
  padding: 2.5rem 1.5rem;
  background-color: #ffffff;
  text-align: center;
  ${(props) => props.theme.screenSize.desktop} {
    padding: 2.5rem 7.5rem;
  }
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 75rem;
  margin: 0 auto;
  position: relative;
  row-gap: 1rem;

  ${(props) => props.theme.screenSize.desktop} {
    flex-direction: row;
  }
`;

const StepIndex = styled.div`
  height: 50px;
  width: 50px;
  background-color: ${props => props.theme.brandPrimary};
  border: 2px solid ${props => props.theme.brandPrimary};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 22px;

  ${(props) => props.theme.screenSize.desktop} {
    height: 60px;
    width: 60px;
    font-size: 28px;
  }
`;

const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 5px;
  min-height: 4rem;

  ${(props) => props.theme.screenSize.desktop} {
    width: ${(props) => `calc(100% / ${props.numberOfItems})`};
  }
`;

const StepCopy = styled.div`
  align-items: center;
  margin-left: 1rem;
  font-size: 17px;
  width: calc(100% - 100px);
  text-align: start;

  ${(props) => props.theme.screenSize.desktop} {
    font-size: 24px;
  }
`;
const StepHeading = styled.h4`
  font-size: 20px;
  line-height: 20.5px;
  margin-bottom: 5px;

  ${(props) => props.theme.screenSize.desktop} {
    font-size: 24px;
    line-height: 24.5px;
    margin-bottom: 10px;
  }
`;

const StepSubHeading = styled.p`
  font-size: 14px;
  line-height: 20px;
  ${(props) => props.theme.screenSize.desktop} {
    font-size: 16px;
  }
`;

const Steps = ({ steps }) => {
  const stepsData = steps?.map((step) => step?.fields);

  return (
    stepsData?.length && (
      <TopContainer>
        <StepsContainer>
          {stepsData?.map((step, i) => {
            return (
              <Step numberOfItems={stepsData.length} key={`step-number-${i}`}>
                <StepIndex>{i + 1}</StepIndex>
                <StepCopy>
                  <StepHeading>{step.name}</StepHeading>
                  <StepSubHeading>{step.description}</StepSubHeading>
                </StepCopy>
              </Step>
            );
          })}
        </StepsContainer>
      </TopContainer>
    )
  );
};
export default Steps;
