import React from 'react';
import styled from 'styled-components/macro';
import { bool, object } from 'prop-types';
import useIsMobile from '../../theme/useIsMobile';
import { useEffect } from 'react';
import { useState } from 'react';
import { dataLayerPush } from '../../Utils/data-layer-push';

const StyledContainer = styled.div`
  height: auto;
  width: 100%;
  cursor: pointer;
  padding-top: 60px;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const SalientTerms = styled.p`
  background: ${props => props.theme.brandPrimary};
  padding: 10px 60px;
  color: #fcfcfc;
  a {
    color: yellow !important;
    margin-left: 2px;
  }
  font-size: 10px;
  text-align: left;
  ${(props) => props.theme.screenSize.tablet} {
    padding: 14px;
    font-size: 9px;
  }
`;

const Banner = ({ content, newCustomer }) => {
  const [bannerImg, setBannerImg] = useState();
  const isMobile = useIsMobile();

  const bannerImgMobile =
    content?.banner?.fields?.mobileImage?.fields?.file?.url;
  const bannerImgDesktop =
    content?.banner?.fields?.desktopImage?.fields?.file?.url;

  const bannerImgNewMobile =
    content?.bannerNewCustomer?.fields?.mobileImage?.fields?.file?.url;
  const bannerImgNewDesktop =
    content?.bannerNewCustomer?.fields?.desktopImage?.fields?.file?.url;
  const bannerTarget = content?.banner?.fields?.href || 'https://tote.co.uk';
  const bannerTargetNewCustomer =
    content?.bannerNewCustomer?.fields?.href || 'https://tote.co.uk';

  useEffect(() => {
    if (newCustomer) {
      setBannerImg(isMobile ? bannerImgNewMobile : bannerImgNewDesktop);
    } else setBannerImg(isMobile ? bannerImgMobile : bannerImgDesktop);
  }, [
    isMobile,
    newCustomer,
    bannerImgMobile,
    bannerImgDesktop,
    bannerImgNewMobile,
    bannerImgNewDesktop
  ]);

  return (
    content &&
    bannerImgDesktop &&
    bannerImgMobile && (
      <>
        <StyledContainer
          onClick={() => {
            window
              .open(
                newCustomer ? bannerTargetNewCustomer : bannerTarget,
                '_blank'
              )
              .focus();
            dataLayerPush("offersSiteInteraction", { interactionName: "Banner Clicked", interactionValue: `${newCustomer ? "New" : "Existing"} Customer` });
          }}
        >
          <img src={bannerImg} alt="banner" />
        </StyledContainer>
        <SalientTerms>
          {content?.salientTerms}
          {(newCustomer &&
            content?.welcomeOfferTerms &&
            `| ${content?.welcomeOfferTerms}`) ||
            ''}
          <a id="lpFulltcs" href="#fullterms">
            Full T&amp;Cs apply.
          </a>
        </SalientTerms>
      </>
    )
  );
};

Banner.propTypes = {
  content: object,
  existingCustomer: bool
};

Banner.defaultProp = {
  content: {},
  existingCustomer: false
};

export default Banner;
