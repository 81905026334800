import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import GlobalStyle from "./theme/global-style";
import { ThemeProvider } from "styled-components/macro";
import theme from "./theme/theme";
import CookieModal from "./components/cookie-modal/cookie-modal";
import GoogleTagManager from "./components/google-tag-manager/google-tag-manager";

import DefaultPage from "./pages/default-page/default-page";
import { useEffect, useState } from "react";
import { getLandingPages } from "./pages/service";
import ContentPage from "./pages/content-page/content-page";
import LoadingPage from "./pages/loading-page/loading-page";
import { getCountryCode, getIsExitingCustomer } from "./pages/helpers";

import {
  getDisplayRegion,
  useGeoLocationScript
} from "./components/cookie-modal/geo-location";
import { GlobalProvider } from "./App.context";

function App() {
  const [newCustomer, setNewCustomer] = useState(false);
  const [landingPages, setLandingPages] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [displayRegion, setDisplayRegion] = useState();
  const [styleTheme, setStyleTheme] = useState(theme);

  useEffect(() => {
    setNewCustomer(!getIsExitingCustomer());
    setDisplayRegion(getCountryCode());
  }, []);

  useGeoLocationScript(() => {
    setLoaded(true);
  });

  useEffect(() => {
    const onDisplayRegionChange = (countryCode) => {
      setDisplayRegion(countryCode);
    };
    if (loaded && !displayRegion) {
      getDisplayRegion(onDisplayRegionChange);
    }
  }, [loaded, displayRegion]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getLandingPages(displayRegion);
      setLandingPages(response || []);
    };

    if (displayRegion) fetchData();
  }, [displayRegion]);

  return (
    <div className="App">
      <ThemeProvider theme={styleTheme}>
        <GlobalProvider>
          <GlobalStyle />
          <GoogleTagManager />
          <CookieModal />
          <Header newCustomer={newCustomer} />
          {!landingPages
            ? <LoadingPage />
            : <BrowserRouter>
                <Routes>
                  <Route
                    index
                    element={
                      <DefaultPage
                        newCustomer={newCustomer}
                        displayRegion={displayRegion}
                      />
                    }
                  />
                  {landingPages?.map((item) => (
                    <Route
                      key={item?.fields?.identifier}
                      path={item?.fields?.identifier}
                      exact
                      element={
                        <ContentPage
                          pageContent={item?.fields}
                          newCustomer={newCustomer}
                          setTheme={setStyleTheme}
                        />
                      }
                    />
                  ))}
                  <Route
                    path="/*"
                    element={
                      <DefaultPage
                        newCustomer={newCustomer}
                        displayRegion={displayRegion}
                      />
                    }
                  />
                </Routes>
              </BrowserRouter>
          }
          <Footer />
        </GlobalProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
