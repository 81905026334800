import { useState, useContext, createContext } from "react";

export const GlobalContext = createContext(undefined);

export const GlobalProvider = ({ children }) => {
  const [joinHref, setJoinHref] = useState("https://tote.co.uk/register");

  return (
    <GlobalContext.Provider
      value={{
        joinHref,
        setJoinHref
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error("Global context must be defined");
  }

  return context;
};
