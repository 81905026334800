import React from 'react';
import styled from 'styled-components/macro';
import { object } from 'prop-types';
import { StyledHeading } from '../style';
import RecentWinnersImgDesktop from './img/previous-winners-full-width.png';
import RecentWinnersImgMobile from './img/previous-winners-sub-600.png';

const RecentWinnersContainer = styled.div`
  width: 100%;
  background-color: #f4f4f4;
  padding-top: 10px;
  text-align: center;
  ${(props) => props.theme.screenSize.desktop} {
    padding-top: 40px;
  }
`;

const StyledImage = styled.div`
  width: 100%;
  background-image: linear-gradient(
    45deg,
    rgba(255, 6, 34, 1) 0%,
    rgba(255, 0, 205, 1) 100%
  );
  background-image: url(${(props) => props.mobileImage});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 20rem;

  ${(props) => props.theme.screenSize.desktop} {
    background-image: url(${(props) => props.dektopImage});
    height: 14rem;
  }
`;

const RecentWinners = ({ content }) => {
  return (
    <RecentWinnersContainer>
      <StyledHeading>Recent Winners</StyledHeading>
      <StyledImage
        mobileImage={content?.recentWinnersImgMobile || RecentWinnersImgMobile}
        dektopImage={
          content?.recentWinnersImgDesktop || RecentWinnersImgDesktop
        }
      />
    </RecentWinnersContainer>
  );
};

RecentWinners.propTypes = {
  content: object
};

RecentWinners.defaultProp = {
  content: {}
};

export default RecentWinners;
