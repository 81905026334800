import { createClient } from 'contentful';
import styled from 'styled-components/macro';
import config from '../config';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { NavLink } from 'react-router-dom';

const StyledH1 = styled.h1`
  margin: 0;
  font-size: 1.5rem;
  line-height: 26px;
`;

const StyledH2 = styled.h2`
  margin: 0;
  font-size: 1.25rem;
  line-height: 24px;
`;

const StyledH3 = styled.h3`
  margin: 0;
  font-size: 1rem;
  line-height: 20px;
`;

const StyledH4 = styled.h4`
  margin: 0;
  font-size: 0.85rem;
  line-height: 17px;
`;

const StyledH5 = styled.h5`
  margin: 0;
  font-size: 0.75rem;
  line-height: 16px;
`;

const StyledH6 = styled.h6`
  margin: 0;
  font-size: 0.625rem;
  line-height: 14px;
  font-family: 'Avant Garde Gothic Book';

  ${(props) => props.theme.screenSize.desktop} {
    font-size: 0.75rem;
    line-height: 16px;
  }
`;

const StyledP = styled.p`
  margin: 0;
  font-size: 0.75rem;
  line-height: 16px;
  font-family: 'Avant Garde Gothic Book';

  ${(props) => props.theme.screenSize.desktop} {
    font-size: 0.875rem;
    line-height: 18px;
  }
`;

const StyledLink = styled.a`
  position: relative;
  top: -5.5rem;
  visibility: hidden;
  ${(props) => props.theme.screenSize.desktop} {
    top: -7rem;
  }
`;
const StyledOLList = styled.ol`
  padding-left: 12px;
  margin: 0;
  li::marker {
    font-size: 12px;
    color: #000000;
  }

  li {
    text-align: left;
    padding-left: 5px;
    p {
      font-family: ${(props) => props.theme.fonts.light};
      color: #000000;
      font-size: 12px;
    }
    ul {
      li {
        padding-left: 5px;
      }
    }
  }
`;

export const getClient = () => {
  return createClient({
    space: config.contentful.space,
    accessToken: config.contentful.accessToken,
    environment: config.contentful.environment,
    host: config.contentful.host,
    removeUnresolved: true
  });
};

export const getOptions = (isInModal = false) => {
  const openNewWindow = {};
  if (isInModal) {
    openNewWindow.target = '_blank';
    openNewWindow.rel = 'noopener noreferrer';
  }

  return {
    renderNode: {
      [INLINES.HYPERLINK]: (link) => {
        const { data, content } = link;
        if (content[0].value === 'jump link') {
          return <StyledLink name={data.uri} />;
        }

        if (data.uri.startsWith('#')) {
          return <a href={data.uri}>{content[0].value}</a>;
        }

        if (data.uri.startsWith('http') || data.uri.startsWith('mailto')) {
          return (
            <a href={data.uri} target="_blank" rel="noopener noreferrer">
              {content[0].value}
            </a>
          );
        }

        return (
          <NavLink to={data.uri} {...openNewWindow}>
            {content[0].value}
          </NavLink>
        );
      },
      [INLINES.ENTRY_HYPERLINK]: (entry) => {
        const { data, content } = entry;
        return (
          <NavLink to={`/${data.target.fields.slug}`} {...openNewWindow}>
            {content[0].value}
          </NavLink>
        );
      },
      [INLINES.ASSET_HYPERLINK]: (asset) => {
        const { data, content } = asset;
        return (
          <a
            href={data.target.fields.file.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {content[0].value}
          </a>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (asset) => {
        const { data } = asset;
        if (data.target.fields.file.contentType.includes('image')) {
          return (
            <img
              src={data.target.fields.file.url}
              alt={data.target.fields.title}
            />
          );
        }

        return null;
      },
      [BLOCKS.EMBEDDED_ENTRY]: (entry) => {
        const { nodeType, data } = entry;
        if (nodeType === 'embedded-entry-block') {
          return (
            <NavLink to={`/${data.target.fields.slug}`} {...openNewWindow}>
              {data.target.fields.title}
            </NavLink>
          );
        }

        return null;
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        const { content } = node;
        const { value } = content[0];
        return (
          <>
            <StyledLink name={value.toLowerCase().trim()} />
            <StyledH1>{children}</StyledH1>
          </>
        );
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        const { content } = node;
        const { value } = content[0];
        return (
          <>
            <StyledLink name={value.toLowerCase().trim()} />
            <StyledH2>{children}</StyledH2>
          </>
        );
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        const { content } = node;
        const { value } = content[0];
        return (
          <>
            <StyledLink name={value.toLowerCase().trim()} />
            <StyledH3>{children}</StyledH3>
          </>
        );
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        const { content } = node;

        const { value } = content[0];
        return (
          <>
            <StyledLink name={value.toLowerCase().trim()} />
            <StyledH4>{children}</StyledH4>
          </>
        );
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        const { content } = node;
        const { value } = content[0];
        return (
          <>
            <StyledLink name={value.toLowerCase().trim()} />
            <StyledH5>{children}</StyledH5>
          </>
        );
      },
      [BLOCKS.HEADING_6]: (node, children) => {
        const { content } = node;
        const { value } = content[0];
        return (
          <>
            <StyledLink name={value.toLowerCase().trim()} />
            <StyledH6>{children}</StyledH6>
          </>
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const { content } = node;
        const { value } = content[0];
        return (
          <>
            <StyledLink name={value.toLowerCase().trim()} />
            <StyledP>{children}</StyledP>
          </>
        );
      },
      [BLOCKS.OL_LIST]: (node, children) => {
        // const { content } = node;
        // const { value } = content?.[0];
        return (
          <>
            <StyledOLList>{children}</StyledOLList>
          </>
        );
      }
    },
    renderText: (text) => {
      return text.split('\n').reduce((children, textSegment, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    }
  };
};
