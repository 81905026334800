import standardTheme from './theme';

const fantasyTheme = {
  ...standardTheme,
  headerBrandPrimary: '#340B55',
  headerMenuHover: '#340B55',
  brandPrimary: '#340B55',
  brandPrimaryDark: '#340B55',
  brandPrimaryLight: '#340B5580',
  buttonText: '#340B55',
  fantasyBrandSecondary: '#54F0D6',
  fantasyDarkGrey: '#f0f1f2',
  fantasyRed: '#EB416C',
  fantasyYellow: '#FFB700',
  fantasyGray: '#F8F8F8',
  fantasyVoid: '#ffe7c4',
  fantasyGradient: 'linear-gradient(234.98deg, #6d57a1 0%, #340b55 100%)',
  fantasyBorderColor: '#f0f1f2',
  fantasyBrightYellow: '#F9B233',
  brandPrimarySemiDark: '#DB5271',
  buttonColor: '#DB5171',
  fonts: {
    ...standardTheme.fonts,
    light: 'SourceSansProRegular400',
    normal: 'SourceSansProRegular400',
    bold: 'SourceSansProSemiBold600'
  }
};

export default fantasyTheme;
