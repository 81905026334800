import { COOKIE_TOTE_PROMO_CODE, setSecureCookie } from "./cookie";

export const setPromoCodeCookie = (hrefFromContentful) => {
  const hrefUrl = new URL(hrefFromContentful);
  const hrefUrlSearchParams = new URLSearchParams(hrefUrl.search);
  const hrefPromoCodeParam = hrefUrlSearchParams.get("promo");

  if (!hrefPromoCodeParam) {
    return;
  }

  setSecureCookie(COOKIE_TOTE_PROMO_CODE, hrefPromoCodeParam, 24);
};
