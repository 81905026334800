import React from "react";
import styled from "styled-components/macro";
import Logo from "./img/tote-logo.svg";
import { useGlobalContext } from "../../App.context";
import { dataLayerPush } from "../../Utils/data-layer-push";

const HeaderContainer = styled.div`
  width: 100%;
  height: 60px;
  margin: 0px auto;
  position: fixed;
  z-index: 900;
  background-color: ${(props) => props.theme.brandPrimary};

  ${(props) => props.theme.screenSize.tablet} {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 50px;
  }
`;

const StyledLogo = styled.div`
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 25px;

  img {
    height: 25px;
  }

  ${(props) => props.theme.screenSize.tablet} {
    top: 60%;
    left: 60px;
    img {
      height: 20px;
    }
  }
`;

const StyledButton = styled.button`
  all: unset;
  position: absolute;
  top: 10px;
  right: 20px;
  width: 210px;
  height: 40px;
  font-size: 14px;
  text-decoration: none;

  color: black;
  background-color: white;

  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;

  border: 1px solid #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);

  cursor: pointer;
  z-index: 902;

  &:hover {
    filter: brightness(90%);
    color: ${(props) => props.theme.textBlack};
    transition: 0.15s ease-in-out;
  }

  ${(props) => props.theme.screenSize.tablet} {
    right: 10px;
    width: 160px;
    height: 30px;
  }
`;

const Header = ({ newCustomer }) => {
  const { joinHref } = useGlobalContext();

  return (
    <HeaderContainer>
      <StyledLogo>
        <a href="https://tote.co.uk" target="_blank" rel="noreferrer">
          <img src={Logo} alt="Tote" />
        </a>
      </StyledLogo>

      <StyledButton
        onClick={() => {
          window
            .open(newCustomer ? joinHref : "https://tote.co.uk/login", "_blank")
            .focus();
          dataLayerPush("offersSiteInteraction", { interactionName: "Header Button Clicked", interactionValue: `${newCustomer ? "New" : "Existing"} Customer` });
        }}
      >
        {newCustomer ? "Join" : "Log in"}
      </StyledButton>
    </HeaderContainer>
  );
};
export default Header;
