const color = {
  brandPrimary: '#FF0028',
  brandSecondary: '#FFD900',
  headerBrandPrimary: '#FF0028',
  headerMenuHover: '#D2001E',
  brandPrimarySemiDark: '#D2001E',
  brandPrimaryLighterDark: '#690127',
  brandPrimaryDark: '#690127',
  brandPrimaryLight: '#fbe6e9',
  brandOpposite: '#FFFFFF',
  backgroundLight: '#ffffff',
  selectedColor: '#E9E9E9',
  selectedColor2: '#F8F8F8',
  selectedColor3: '#F7F7F7',
  disabled: '#E9E9E9',
  background: '#E5E7E9',
  backgroundLighter: '#F2F3F4',
  hoverBackground: '#F8F8F8',
  overlay: 'rgba(166, 172, 175, .1)',
  textColor: '#626567',
  textBlack: '#17202A',
  textLight: '#A6ACAF',
  textGreen: '#247F1C',
  backgroundGreen: '#28B463',
  borderColor: '#e5e7e9',
  messageDefaultColor: '#FFD900',
  messageDefaultColorLight: '#f7ecaa',
  errorRed: '#cc0000',
  pieStartGradient: '#FF00A9',
  pieStopGradient: '#FF0067',
  appliedPromotion: '#D2001E',
  scoop6Gradient:
    'repeating-linear-gradient(66deg,#ff0028 0%,#ff008e 82%,#ff00d2 100%)',
  singlesGradient: 'linear-gradient(90deg, #FFA000, #FF4908);',
  multisGradient: 'linear-gradient(90deg, #FF0028, #FF00D2);',
  maintenanceGradient:
    'repeating-linear-gradient(66deg,#ff0028 0%,#ff0028 82%,#ff0028 100%)',
  backgroundLightPink: '#FFF2F4',
  backgroundTabSelected: '#951010',
  boderColor: '#EBEBEB',
  buttonText: '#690026',
  barColor: '#FF03D2',
  totePlusColor: '#D20514',
  survivorColor: '#71032A',
  rgba: {
    medium: 'rgba(0,0,0,0.5)',
    light: 'rgba(0, 0, 0, 0.3)'
  },
  loading: {
    primary: '#a3acae',
    secondary: '#ecebeb'
  },
  gradients: {
    placepot:
      'background-image: repeating-linear-gradient(67deg, #FF0023, #FF008E 49.13%, #FF00D2 90.9%, #690026 150%)',
    placepot7:
      'background-image: repeating-linear-gradient(67deg, #FF0023, #FF008E 49.13%, #FF00D2 90.9%, #690026 150%)',
    jackpot:
      'background-image: repeating-linear-gradient(67deg, #FF0023, #FF008E 49.13%, #FF00D2 90.9%, #690026 150%)',
    treble:
      'background-image: repeating-linear-gradient(67deg, #FF0023, #FF008E 49.13%, #FF00D2 90.9%, #690026 150%)',
    irishjackpot:
      'background-image: repeating-linear-gradient(67deg, #FF0023, #FF008E 49.13%, #FF00D2 90.9%, #690026 150%)',
    pick4:
      'background-image: repeating-linear-gradient(67deg, #FF0023, #FF008E 49.13%, #FF00D2 90.9%, #690026 150%)',
    v64: 'background-image: repeating-linear-gradient(67deg, #FF0023, #FF008E 49.13%, #FF00D2 90.9%, #690026 150%)',
    v75: 'background-image: repeating-linear-gradient(67deg, #FF0023, #FF008E 49.13%, #FF00D2 90.9%, #690026 150%)',
    scoop6:
      'background-image: repeating-linear-gradient(67deg, #FF0023, #FF008E 49.13%, #FF00D2 90.9%, #690026 150%)',
    quadpot:
      'background-image: repeating-linear-gradient(67deg, #FF0023, #FF008E 49.13%, #FF00D2 90.9%, #690026 150%)',
    trifecta:
      'background-image: repeating-linear-gradient(90deg, #FFA000, #FFA000 30%, #FF4908 71.3%, #FF4908 150%)',
    exacta:
      'background-image: repeating-linear-gradient(90deg, #FFA000, #FFA000 30%, #FF4908 71.3%, #FF4908 150%)',
    quinella:
      'background-image: repeating-linear-gradient(90deg, #FFA000, #FFA000 30%, #FF4908 71.3%, #FF4908 150%)',
    win: 'background-image: repeating-linear-gradient(90deg, #FFA000, #FFA000 30%, #FF4908 71.3%, #FF4908 150%)',
    swinger:
      'background-image: repeating-linear-gradient(90deg, #FFA000, #FFA000 30%, #FF4908 71.3%, #FF4908 150%)',
    survivor:
      'background-image: repeating-linear-gradient(67deg, #FF0023, #FF008E 49.13%, #FF00D2 90.9%, #690026 150%)',
    default:
      'background-image: repeating-linear-gradient(67deg, #FF0023, #E90023 49.13%, #D2001E 90.9%, #690026 150%)'
  },
  betHeader: {
    topup: '#fff',
    rollover: '#ffd900',
    today: '#690026'
  },
  trafficLightMessage: {
    red: {
      dark: '#D2001E',
      light: 'rgba(210,0,30,0.15)'
    },
    amber: {
      dark: '#FF4908',
      light: 'rgba(255,73,8,0.15)'
    },
    green: {
      dark: '#62C159',
      light: 'rgba(98,193,89,0.15)'
    }
  }
};
export default color;
