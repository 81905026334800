import color from './partials/theme.color';
import fontSizes from './partials/theme.fontSizes';
import screenSize from './partials/theme.screenSize';
import scrollbar from './partials/theme.scrollbar';
import fonts from './partials/theme.fonts';

const theme = {
  ...color,
  fontSizes,
  scrollbar,
  screenSize,
  fonts
};

export default theme;
