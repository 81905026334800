import { useEffect } from "react";
import { COOKIE_DISPLAY_REGION, setSecureCookie } from "../../Utils/cookie";

export const useGeoLocationScript = (callback) => {
  useEffect(() => {
    const existingScript = document.getElementById("googleMaps");
    if (!existingScript) {
      const script = document.createElement("script");

      script.src = "https://geoip-js.com/js/apis/geoip2/v2.1/geoip2.js";
      script.type = "text/javascript";
      script.id = "geoip2";

      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  }, [callback]);
};

export const getDisplayRegion = (onChange) => {
  let countryCode = "GB";
  var updateCityText = function (geoipResponse) {
    countryCode = geoipResponse.country.iso_code || "GB";
    setSecureCookie(COOKIE_DISPLAY_REGION, countryCode, 7776000 * 100);
    onChange(countryCode);
    return countryCode;
  };

  var onSuccess = function (geoipResponse) {
    updateCityText(geoipResponse);
  };

  // If we get an error, we will display an error message
  var onError = function (error) {
    setSecureCookie(COOKIE_DISPLAY_REGION, "GB", 7776000 * 100);
    onChange("GB");
    return "GB";
  };

  if (typeof window.geoip2 !== "undefined") {
    window.geoip2.city(onSuccess, onError);
  }
};
