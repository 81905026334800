import { useEffect } from "react";
import TagManager from "react-gtm-module";
import config from "../../config";

const GoogleTagManager = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: config.googleTagManager.id });
  }, []);

  return <div />;
};

export default GoogleTagManager;
