import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { array, bool } from 'prop-types';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { getOptions } from '../../Utils/clientSetup';
import { StyledHeadingTC } from '../style';

const TCTopContainer = styled.div`
  background: #ececec;
  padding: 40px 0px;
  ${(props) => props.theme.screenSize.desktop} {
    padding: 60px 0px;
  }
`;

const SpaceContainer = styled.div`
  width: 85%;
  margin: 0px auto;
  ${(props) => props.theme.screenSize.desktop} {
    width: 900px;
  }
`;

const Title = styled.h4`
  color: black;
  font-size: 16px;
  padding: 20px 0;
  text-align: left;
`;

const TCItem = styled.div``;

const TermsAndConditions = ({ termsAndConditionsList, newCustomer }) => {
  const [termsList, setTermsList] = useState([]);

  useEffect(() => {
    if (newCustomer) {
      setTermsList(termsAndConditionsList);
    } else
      setTermsList(
        termsAndConditionsList?.filter(
          (item) => !item?.fields?.betType?.includes('welcome')
        )
      );
  }, [newCustomer, termsAndConditionsList]);

  return (
    <TCTopContainer>
      <SpaceContainer>
        <StyledHeadingTC id="fullterms">Terms And Conditions</StyledHeadingTC>
        {termsList?.map((item, index) => (
          <TCItem key={`${item?.title}-${index}`}>
            <Title>{item.fields.title}</Title>
            <div>
              {documentToReactComponents(
                item.fields.termsAndConditionsText,
                getOptions()
              )}
            </div>
          </TCItem>
        ))}
      </SpaceContainer>
    </TCTopContainer>
  );
};

TermsAndConditions.propTypes = {
  termsAndConditionsList: array,
  newCustomer: bool
};

TermsAndConditions.defaultProp = {
  termsAndConditionsList: [],
  newCustomer: false
};
export default TermsAndConditions;
