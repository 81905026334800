import React, { useEffect } from "react";
import { formatIdentifier } from "../helpers";
import { Helmet } from "react-helmet";
import PageContent from "../../components/page-content/page-content";
import { themeLookup } from "../../theme/theme-lookup";
import { useGlobalContext } from "../../App.context";
import { setPromoCodeCookie } from "../../Utils/set-promo-code-cookie";
import { dataLayerPush } from "../../Utils/data-layer-push";

const ContentPage = ({ pageContent, newCustomer, setTheme }) => {
  const { setJoinHref } = useGlobalContext();

  useEffect(() => {
    if (pageContent) {
      dataLayerPush("offersSiteInteraction", { interactionName: "Landing Page Loaded", interactionValue: pageContent?.identifier });
    }
  }, [pageContent]);

  useEffect(() => {
    if (pageContent?.productType) {
      setTheme(themeLookup[pageContent.productType]);
    }

    const joinHrefFromContentful = pageContent?.bannerNewCustomer?.fields?.href;
    if (!joinHrefFromContentful) {
      return;
    }

    setJoinHref(joinHrefFromContentful);
    setPromoCodeCookie(joinHrefFromContentful);
  }, [pageContent, setTheme, setJoinHref]);

  return (
    <>
      <Helmet>
        <title>{`Tote | ${
          formatIdentifier(pageContent?.identifier) || ""
        } `}</title>
        <link rel="canonical" href="https://tote.co.uk/racing/free-bet" />
        <meta name="description" content="" />
        <meta name="author" content="Tote" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
        />
      </Helmet>
      {pageContent && (
        <PageContent content={pageContent} newCustomer={newCustomer} />
      )}
    </>
  );
};
export default ContentPage;
