import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageContent from "../../components/page-content/page-content";
import LoadingPage from "../loading-page/loading-page";
import { getContent } from "../service";
import { useGlobalContext } from "../../App.context";
import { setPromoCodeCookie } from "../../Utils/set-promo-code-cookie";
import { dataLayerPush } from "../../Utils/data-layer-push";

const DefaultPage = ({ newCustomer, displayRegion }) => {
  const [content, setContent] = useState();
  const { setJoinHref } = useGlobalContext();

  useEffect(() => {
    dataLayerPush("offersSiteInteraction", { interactionName: "Landing Page Loaded", interactionValue: "default" });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getContent("default-page", displayRegion);
      setContent(response);

      const joinHrefFromContentful = response?.bannerNewCustomer?.fields?.href;
      if (!joinHrefFromContentful) {
        return;
      }

      setJoinHref(joinHrefFromContentful);
      setPromoCodeCookie(joinHrefFromContentful);
    };

    if (displayRegion) fetchData();
  }, [displayRegion, setJoinHref]);

  return (
    <>
      <Helmet>
        <title>Tote | Offers</title>
        <link rel="canonical" href="https://tote.co.uk/racing/free-bet" />
        <meta name="description" content="" />
        <meta name="author" content="Tote" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
        />
      </Helmet>
      {content ? (
        <PageContent content={content} newCustomer={newCustomer} />
      ) : (
        <LoadingPage />
      )}
    </>
  );
};
export default DefaultPage;
