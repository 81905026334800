import { useEffect, useState } from 'react';
import theme from './theme'

const useIsMobile = () => {
  const mediaQuery = theme.screenSize.tablet.replace(/^@media\s/, '');

  const [matched, setMatched] = useState(
    !!window.matchMedia(mediaQuery).matches
  );

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    const documentChangeHandler = () => setMatched(!!mediaQueryList.matches);

    mediaQueryList.addListener(documentChangeHandler);

    documentChangeHandler();
    return () => {
      mediaQueryList.removeListener(documentChangeHandler);
    };
  }, [mediaQuery]);

  return matched;
};

export default useIsMobile;
