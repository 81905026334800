import { createGlobalStyle } from 'styled-components/macro';
import ToteHeadline from '../fonts/ToteHeadline.otf';
import ITCAvantGardeGothicSTDBook from '../fonts/itc-avant-garde-gothic-std-book.otf';
import ITCAvantGardeGothicSTDDemi from '../fonts/itc-avant-garde-gothic-std-demi.otf';
import Teko from '../fonts/Teko-SemiBold.ttf';
import SourceSansProBold from '../fonts/SourceSansPro-Bold.ttf';
import SourceSansProRegular from '../fonts/SourceSansPro-Regular.ttf';
import SourceSansProSemiBold from '../fonts/SourceSansPro-SemiBold.ttf';
import theme from './theme';

const getScrollbarWidth = () => {
    const isChrome =
        /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isSafari =
        /Safari/.test(navigator.userAgent) &&
        /Apple Computer/.test(navigator.vendor);

    if (isChrome || isSafari) {
        return theme.scrollbar.width;
    }

    return '17px';
};

// Tote Headline is the main font.
// Avant Garde Gothic Book is the 'light' font
// Avant Garde Gothic Demi is the 'bold' font
// Use the Demi font rather than tote headline where bold text must be displayed next to light text
const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Tote Headline';
        font-display: fallback;
        src: url(${ToteHeadline}) format('opentype');
    }

    @font-face {
        font-family: 'Avant Garde Gothic Book';
        font-display: fallback;
        src: url(${ITCAvantGardeGothicSTDBook}) format('opentype');
    }

    @font-face {
        font-family: 'Avant Garde Gothic Demi';
        font-display: fallback;
        src: url(${ITCAvantGardeGothicSTDDemi}) format('opentype');
    }

    @font-face {
        font-family: 'Teko';
        font-display: fallback;
        src: url(${Teko}) format('truetype');
    }

    @font-face {
        font-family: 'SourceSansProBold700';
        font-display: fallback;
        src: url(${SourceSansProBold}) format('truetype');
    }

    @font-face {
        font-family: 'SourceSansProRegular400';
        font-display: fallback;
        src: url(${SourceSansProRegular}) format('truetype');
    }

    @font-face {
        font-family: 'SourceSansProSemiBold600';
        font-display: fallback;
        src: url(${SourceSansProSemiBold}) format('truetype');
    }

    * {
        font-family: ${props => props.theme.fonts.normal}, arial, sans-serif;
        scrollbar-width: ${props => props.theme.scrollbar.width};

        ::-webkit-scrollbar {
            width: ${props => props.theme.scrollbar.width};
        }

        ::-webkit-scrollbar-track {
            background: ${props => props.theme.backgroundLight};
        }

        ::-webkit-scrollbar-thumb {
            background: ${props => props.theme.brandPrimarySemiDark};
            border-radius: ${props => props.theme.scrollbar.width};
        }
    }

    html {
        box-sizing: border-box;
        line-height: 1.4;
    }
    
    /* *, *:before, *:after {
        box-sizing: inherit;
    } */

    body {
        overflow-x: hidden;
        background-color: ${props => props.theme.brandPrimary};

        &.no-scroll {
            overflow: hidden;

            ${props => props.theme.screenSize.desktop} {
                .position-app-when-showing-backdrop {
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }

                .shift-when-showing-backdrop {
                    padding-right: ${getScrollbarWidth(theme)};
                }

                .intercom-launcher {
                    margin-right: ${getScrollbarWidth(theme)};
                }
            }
        }
    }

    a, a:link, a:hover, a:active {
        color: ${props => props.theme.brandPrimary};
        font-weight: 600;
        text-decoration: none;
    }

    a.blackLink, button {
        color: ${props => props.theme.textBlack};
    }

    p {
        margin: 0;
        line-height: 16px;
        font-family: ${props => props.theme.fonts.light};
    }

    h4 {
        margin: 0;
        font-family: ${props => props.theme.fonts.bold};
    }


    /**
    * until https://github.com/facebook/create-react-app/issues/11771
    * gets fixed, let's hide the problem and not address it
    */
    body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
        display: none;
    }
`;

export default GlobalStyle;
