import { getClient } from "../Utils/clientSetup";

export const getContent = async (identifier, displayRegion) => {
  try {
    const countryCode = displayRegion || "GB";
    const query = {
      content_type: "offersLandingPage",
      locale: `en-${countryCode}`,
      "fields.identifier[in]": identifier,
      include: 2,
    };

    const response = await getClient().getEntries(query);

    return response?.items?.[0].fields;
  } catch (error) {
    return undefined;
  }
};

export const getLandingPages = async (displayRegion) => {
  try {
    const countryCode = displayRegion || "GB";
    const query = {
      content_type: "offersLandingPage",
      locale: `en-${countryCode}`,
      include: 2,
    };

    const response = await getClient().getEntries(query);

    return response?.items;
  } catch (error) {
    return [];
  }
};
