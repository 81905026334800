const config = {
  contentful: {
    space: window._env_.REACT_APP_CONTENTFUL_SPACE,
    accessToken: window._env_.REACT_APP_CONTENTFUL_ACCESSTOKEN,
    environment: window._env_.REACT_APP_CONTENTFUL_ENVIRONMENT,
    host: window._env_.REACT_APP_CONTENTFUL_CDN
  },
  googleTagManager: {
    id: window._env_.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  },
};

export default config;
