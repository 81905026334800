import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
  COOKIE_ALLOW_ESSENTIAL,
  getCookie,
  setSecureCookie
} from '../../Utils/cookie';
import { dataLayerPush } from '../../Utils/data-layer-push';

const CookieContainer = styled.div`
  position: fixed;
  z-index: 901;
  left: 0;
  top: 0;
  font-family: ToteHeadline, arial, sans-serif;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
`;

const Content = styled.div`
  padding: 2rem;
  background-color: white;
  margin: 2rem auto;
  width: 72%;
  ${(props) => props.theme.screenSize.desktop} {
    width: 40%;
  }
`;

const StyledHeader = styled.h2`
  font-size: 20px;
  margin: 0 0 10px 0;
  text-align: left;

  ${(props) => props.theme.screenSize.desktop} {
    font-size: 20px;
  }
`;

const StyledParagraph = styled.p`
  font-size: 14px;
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.light};
  line-height: 1.5rem;
  text-align: left;

  ${(props) => props.theme.screenSize.desktop} {
    font-size: 14px;
    line-height: 2rem;
    margin: 0;
  }

  a {
    font-family: ${({ theme }) => theme.fonts.bold};
  }
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  margin-top: 10px;
`;

const StyledButton = styled.button`
  all: unset;
  height: 45px;
  font-size: 14px;
  border: 2px solid #ff0028;
  border-radius: 3px;
  color: #000000;
  cursor: pointer;

  ${(props) => props.theme.screenSize.desktop} {
    font-size: 18px;
  }
`;

const AcceptButton = styled(StyledButton)`
  color: white;
  background-color: #ff0028;
`;

const CookieModal = () => {
  const [showModal, setShowModal] = useState(true);

  const cookie = getCookie(COOKIE_ALLOW_ESSENTIAL);

  const onAcceptCookies = () => {
    setShowModal(false);
    setSecureCookie(COOKIE_ALLOW_ESSENTIAL, true, 31556926 * 100);
    dataLayerPush("essentialCookieSet");
  };

  const onManageCookies = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    window.location.href =
      'https://tote.co.uk/manage-cookies?redirect=https://offers.tote.co.uk&' +
      urlParams;
  };

  return (
    !cookie &&
    showModal && (
      <CookieContainer className="cookie-modal-overlay">
        <Content className="cookie-modal">
          <StyledHeader>🍪</StyledHeader>
          <StyledHeader>
            We use cookies to deliver essential functionality and optimise your
            experience on our website.
          </StyledHeader>
          <StyledParagraph>
            You can manage your preferences at any time.
          </StyledParagraph>
          <StyledParagraph>
            Want to know more? Check out our
            <a href="https://tote.co.uk/cookie-policy" id="cookiepolicylink">
              {` 'Cookies Policy' `}
            </a>
            page.
          </StyledParagraph>
          <ButtonsContainer>
            <StyledButton onClick={onManageCookies} variant="outline-primary">
              Manage Cookies
            </StyledButton>
            <AcceptButton
              onClick={onAcceptCookies}
              variant="outline-primary"
              data-testid="accept-cookies-button"
            >
              Accept
            </AcceptButton>
          </ButtonsContainer>
        </Content>
      </CookieContainer>
    )
  );
};
export default CookieModal;
