import React from 'react';
import Banner from '../../components/banner/banner';
import PoolBetting from '../../components/pool-betting/pool-betting';
import Steps from '../../components/steps/steps';
import TermsAndConditions from '../../components/terms-and-conditions/terms-and-conditions';
import RecentWinners from '../recent-winners/recent-winners';
import TextCopy from '../text-copy/text-copy';

const PageContent = ({ content, newCustomer }) => {
  return (
    <>
      <Banner content={content} newCustomer={newCustomer} />
      <Steps steps={content?.stepsList} />
      <TextCopy textCopy={content?.textCopy} />
      {content?.showRecentWinners && <RecentWinners />}
      <PoolBetting
        content={{
          title: content?.videoTitle,
          copy: content?.videoCopy,
          videoSrc: content?.videoSrc
        }}
      />
      <TermsAndConditions
        termsAndConditionsList={content?.termsAndConditionsList}
        newCustomer={newCustomer}
      />
    </>
  );
};
export default PageContent;
