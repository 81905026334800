import styled from "styled-components/macro";

const StyledHeading = styled.h2`   
    color: ${props => props.theme.brandPrimary};
    font-size: 24px;
    font-family:${props => props.theme.fonts.normal};
 `;

const StyledHeadingTC = styled.h2`   
    color: black;
    font-size: 30px;
    font-family:${props => props.theme.fonts.normal};
    margin:0;
    text-align:left;
    
 `;
export { StyledHeading, StyledHeadingTC }