import React from 'react';
import styled from 'styled-components/macro';

const PageContainer = styled.div`
  width: 100%;
  background: white;
  height: 100vh;
  text-align: center;
  color: red;
  padding-top: 10rem;

  ${(props) => props.theme.screenSize.desktop} {
    height: 100vh;
  }
`;

const LoadingPage = () => {
  return <PageContainer>Loading...</PageContainer>;
};
export default LoadingPage;
