import { COOKIE_DISPLAY_REGION, COOKIE_TOTE_REGISTERED, getCookie } from "../Utils/cookie"

export const getIsExitingCustomer = () =>
    !!getCookie(COOKIE_TOTE_REGISTERED);

export const getCountryCode = () => getCookie(COOKIE_DISPLAY_REGION);


export const formatIdentifier = (identifier) => {
    const uppercaseString = identifier?.split("-").map(a => a.charAt(0).toUpperCase() + a.substr(1)).join(" ")
    return uppercaseString
}