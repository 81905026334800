import React from 'react';
import styled from 'styled-components/macro';
import { object } from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { getOptions } from '../../Utils/clientSetup';

const TextCopyContainer = styled.div`
  width: 100%;
  background-color: #f4f4f4;
`;
const StyledTextCopy = styled.div`
  padding-top: 10px;
  text-align: center;
  margin: 0px auto;
  width: 95%;
  max-width: 1200px;
  padding-bottom: 4rem;

  h1,
  h2,
  h3,
  h4 {
    font-size: 24px;
    color: ${props => props.theme.brandPrimary};
    padding-top: 30px;
    padding-bottom: 10px;
    font-family: ${(props) => props.theme.fonts.normal};
  }
  p {
    font-family: ${(props) => props.theme.fonts.normal};
  }
  ${(props) => props.theme.screenSize.desktop} {
    padding-top: 40px;
    width: 80%;
  }
`;

const TextCopy = ({ textCopy }) => {
  return (
    textCopy && (
      <TextCopyContainer>
        <StyledTextCopy>
          {documentToReactComponents(textCopy, getOptions())}
        </StyledTextCopy>
      </TextCopyContainer>
    )
  );
};

TextCopy.propTypes = {
  textCopy: object
};

TextCopy.defaultProp = {
  textCopy: {}
};

export default TextCopy;
