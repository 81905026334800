import React from "react";
import styled from "styled-components/macro";
import { object } from "prop-types";
import { StyledHeading } from "../style";
import Button from "../button/button";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getOptions } from "../../Utils/clientSetup";
import { useGlobalContext } from "../../App.context";
import { getIsExitingCustomer } from "../../pages/helpers";
import { dataLayerPush } from "../../Utils/data-layer-push";

const PoolBettingContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 20px 0px;
  text-align: center;
  img {
    width: 100%;
  }
`;
const TextCopy = styled.div`
  p {
    font-size: 14px;
    padding: 1rem;
    font-family: ${(props) => props.theme.fonts.light};
    ${(props) => props.theme.screenSize.desktop} {
      padding: 0;
      padding-bottom: 1rem;
    }
  }
`;

const VideoResponsive = styled.div`
  position: relative;
  padding-bottom: 10rem;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    width: 22rem;
  }

  ${(props) => props.theme.screenSize.desktop} {
    padding-bottom: 25rem;
    iframe {
      width: 723px;
      height: 380px;
    }
  }
`;

const PoolBetting = ({ content }) => {
  const { joinHref } = useGlobalContext();
  const isExistingCustomer = getIsExitingCustomer();

  return content?.videoSrc ? (
    <PoolBettingContainer>
      <StyledHeading>{content?.title || "What is pool betting?"}</StyledHeading>
      {content?.copy ? (
        <TextCopy>
          {documentToReactComponents(content?.copy, getOptions())}
        </TextCopy>
      ) : (
        <>
          <TextCopy>
            When betting with the Tote you are betting into a pool. That means
            all the money staked on each horse in the race is pooled together.
          </TextCopy>
          <TextCopy>
            After the race, everyone who has backed the winner will receive a
            share of that pool money, depending on the size of their original
            stake.
          </TextCopy>
        </>
      )}
      <VideoResponsive>
        <iframe
          //   width="853".
          //   height="480"
          src={content?.videoSrc}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </VideoResponsive>
      <Button
        text="Join here"
        onClick={() => {
          window
            .open(
              isExistingCustomer ? "https://tote.co.uk/login" : joinHref,
              "_blank"
            )
            .focus();
          dataLayerPush("offersSiteInteraction", {
            interactionName: "Content Button Clicked",
            interactionValue: `${
              isExistingCustomer ? "Existing" : "New"
            } Customer`
          });
        }}
      />
    </PoolBettingContainer>
  ) : (
    <></>
  );
};

PoolBetting.propTypes = {
  content: object
};

PoolBetting.defaultProp = {
  content: {}
};

export default PoolBetting;
