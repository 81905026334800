import React from 'react';
import styled from 'styled-components/macro';
import { func, string } from 'prop-types';

const StyledButton = styled.button`
  width: ${(props) => props.width || '100%'};
  all: unset;
  font-size: 20px;
  text-decoration: none;
  color: ${(props) => props.theme.buttonText};
  background-color: ${(props) => props.bgColor || '#ffd900'};
  padding: 4px 6px;
  border: solid 2px #ffd900;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 10px 20px;
  cursor: pointer;

  ${(props) => props.theme.screenSize.desktop} {
    padding: 15px 30px;
  }
`;

const Button = ({ text, color, width, bgColor, onClick }) => {
  return (
    <StyledButton
      color={color}
      width={width}
      bgColor={bgColor}
      onClick={onClick}
    >
      {text}
    </StyledButton>
  );
};

Button.propTypes = {
  text: string,
  color: string,
  width: string,
  bgColor: string,
  onClick: func
};

Button.defaultProp = {
  text: undefined,
  color: undefined,
  width: undefined,
  bgColor: undefined,
  onClick: () => {}
};

export default Button;
