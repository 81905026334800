const screenSize = {
  desktop: '@media screen and (min-width: 992px)',
  desktopCarousel: '@media screen and (min-width: 1142px)',
  desktopResults: '@media screen and (min-width: 1182px)',
  desktopWidth: 992,
  tablet: '@media only screen and (max-width: 680px)',
  tabletWidth: 680,
  mobile: '@media only screen and (max-width: 415px)',
  mobileWidth: 415
};
export default screenSize;
