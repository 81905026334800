import React from 'react';
import styled from 'styled-components/macro';
import GamstopImg from './img/1-gamstop.png';
import GamblingComImg from './img/2-gambling-commission.png';
import EighteenImg from './img/3-18-plus.png';
import GambleAwareImg from './img/4-begambleaware.png';
import GamCareImg from './img/5-gamcare.png';

const FooterContainer = styled.div`
  background-color: #ececec;
  display: flex;
  justify-content: center;
  padding: 3rem;
  flex-direction: column;
  align-items: center;

  ${(props) => props.theme.screenSize.tablet} {
    padding: 1rem;
  }
`;

const FooterImages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 60%;
`;

const Legals = styled.div`
  text-align: center;
  width: 75%;
  margin: 0px auto;
  margin-top: 2rem;

  ${(props) => props.theme.screenSize.tablet} {
    width: 100%;
  }
`;

const Image = styled.img`
  height: 30px;
  padding: 0px 10px;

  ${(props) => props.theme.screenSize.tablet} {
    height: 20px;
    padding: 0px 5px;
  }
`;

const Link = styled.a`
  cursor: pointer;
  color: '#FF0028';
`;

const Text = styled.p`
  font-size: 11px;
  margin: 4px 0;
  line-height: 15px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterImages>
        <Link
          target="_blank"
          href="https://www.gamstop.co.uk/"
          rel="noreferrer"
        >
          <Image src={GamstopImg} alt="GamStop" />
        </Link>
        <Link
          target="_blank"
          href="https://www.gamblingcommission.gov.uk"
          rel="noreferrer"
        >
          <Image src={GamblingComImg} alt="Gambling Commission" />
        </Link>
        <Image src={EighteenImg} alt="Over 18's Only" />
        <Link
          target="_blank"
          href="https://www.begambleaware.org/"
          rel="noreferrer"
        >
          <Image src={GambleAwareImg} alt="Be Gamble Aware" />
        </Link>
        <Link
          target="_blank"
          href="https://www.gamcare.org.uk/"
          rel="noreferrer"
        >
          <Image src={GamCareImg} alt="GamCareImg" />
        </Link>
      </FooterImages>
      <Legals>
        <Text>
          Tote is a trading name of TDCO Limited, which is licensed and
          regulated in Great Britain by the Gambling Commission under account
          number
          <Link
            red
            href="https://www.gamblingcommission.gov.uk/public-register/business/detail/54779"
          >
            {' 54779'}
          </Link>
        </Text>
        <Text>
          TDCO Limited also holds a remote bookmakers licence number 1016589
          issued by the National Excise Licence Office for pool betting
          customers in Ireland.
        </Text>
        <Text>
          TDCO Limited is a company registered in England and Wales under
          company number 11300287 whose registered office is at 6th floor, 6
          Kean Street, London, WC2B 4AS
        </Text>
        <Text>
          Copyright 2021 TDCO Limited. All Rights Reserved. Account holders must
          be 18 or over.
        </Text>
      </Legals>
    </FooterContainer>
  );
};
export default Footer;
