const fontSizes = {
  inputMain: '1.125rem',
  selectOptions: '0.875rem',
  inputSmallLabel: '0.6rem',
  tableHeader: '0.625rem',
  accordion: {
    number: '1.25rem'
  },
  noRacing: {
    normal: '1.5rem',
    large: '2rem'
  },
  account: {
    buttons: {
      placeBetButton: {
        placeBet: '1.3rem'
      },
      viewAccountButton: {
        iconMobile: '1.35rem',
        iconDesktop: '1.75rem'
      },
      viewFundsButton: {
        addFundsIcon: '1.35rem'
      },
      sharedStyles: {
        contentHeaderMobile: '0.8rem',
        contentHeaderDesktop: '1.2rem',
        contentLinkMobile: '0.6rem',
        contentLinkDesktop: '0.9rem'
      }
    }
  },
  header: {
    mobile: '2.5rem',
    desktop: '3rem',
    popup: '1.5rem',
    menuButton: '0.65rem',
    rightButtons: '0.875rem'
  },
  footer: {
    mobile: '0.6rem',
    desktop: '0.833rem'
  },
  inputs: {
    button: '1.5rem'
  },
  registration: {
    header: '1.875rem',
    address: {
      buttonSecondary: '1rem'
    }
  },
  racecard: {
    horseNumberMobile: '1rem',
    horseNumberDesktop: '1.25rem',
    gateNumber: '0.5rem',
    numericsMobile: '0.625rem',
    numericsDesktop: '0.75rem',
    horseNameMobile: '1rem',
    horseNameDesktop: '1.25rem',
    showPrice23: '0.5rem',
    spotlight: '0.75rem',
    ageWeight: '0.875rem'
  },
  raceGrids: {
    extraSmall: '0.5rem',
    small: '0.625rem',
    smallNormal: '0.75rem',
    normal: '1rem',
    large: '1.5rem'
  },
  results: {
    singleResult: {
      tableHeader: '0.75rem'
    }
  },
  poolResultHeader: {
    keyMobile: '0.625rem',
    valueMobile: '1.25rem',
    keyValueDesktop: '1rem',
    keyValueDesktopLarge: '1.75rem',
    singlePoolTotalKeyDesktop: '1.5rem',
    singlePoolTotalValueDesktop: '2rem',
    winPlaceTotalKeyMobile: '1.5rem',
    winPlaceTotalValueMobile: '1.25rem'
  },
  legResults: {
    raceCardHeaders: '1.25rem'
  },
  todaysPools: {
    heading: '1.5rem',
    timeAndPlace: '0.625rem',
    bookPercent: '0.65rem',
    poolValue: '1.125rem',
    noResults: {
      header: '1.875rem',
      subHeader: '1.25rem'
    }
  },
  fastResults: {
    header: '1.125rem',
    runnerMobile: '0.875rem',
    runnerDesktop: '1rem',
    bookPercent: '0.65rem',
    mainMobile: '0.625rem',
    mainDesktop: '0.8rem',
    poolTypeDesktop: '1rem'
  },
  accountOverview: {
    user: {
      initials: '1.4rem'
    },
    greeting: {
      welcome: '1.4rem',
      username: '0.7rem'
    },
    balances: {
      title: '1rem',
      label: '0.85rem',
      amount: '1rem',
      limit: '0.65rem'
    }
  },
  appliedPromotions: {
    extraSmall: '0.6rem',
    small: '0.8rem'
  }
};

export default fontSizes;