const fonts = {
  normal: 'Tote Headline',
  light: 'Avant Garde Gothic Book',
  bold: 'Avant Garde Gothic Demi',
  teko: 'Teko',
  sourceSansProBold700: 'SourceSansProBold700',
  sourceSansProRegular400: 'SourceSansProRegular400',
  sourceSansProSemiBold600: 'SourceSansProSemiBold600'
};
export default fonts;
