export const COOKIE_ALLOW_ESSENTIAL = "tote.allowNonEssential";
export const COOKIE_TOTE_REGISTERED = "tote.registered";
export const COOKIE_DISPLAY_REGION = "tote.displayRegion";
export const COOKIE_TOTE_PROMO_CODE = "tote.regPromoCode";

const domain = "tote.co.uk";

const getExpiryDateTime = (hours) => {
  const date = new Date();
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);

  return date;
};

export const getCookie = (cookieName) => {
  const cookieNameWithEquals = `${cookieName}=`;
  const cookies = document.cookie
    .split(";")
    .filter((item) => item.trim().startsWith(cookieNameWithEquals));

  if (cookies.length === 0) {
    return undefined;
  }

  return cookies[0].split("=")[1];
};

export const setSecureCookie = (name, value, hours) => {
  const expires = hours
    ? `; expires=${getExpiryDateTime(hours).toGMTString()}`
    : "";
  document.cookie = `${name}=${value}${expires}; path=/; domain=${domain}; SameSite=None; Secure`;
};

export const removeCookie = (name) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=${domain}; Secure`;
};
